body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f7f7f7;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

/* styling the svg paths in control center */
.dashed-path {
  stroke-dasharray: 4, 2;
  animation: dash 1s linear infinite;
}

@keyframes dash {
  from {
    stroke-dashoffset: 1000;
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0px #24c142;
  }
  100% {
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }
}

/* scrollbar styling */
*::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
*::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 8px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 8px;
  width: 7px;
  height: 7px;
}
.rdrDayNumber {
  top: 0 !important;
}
.rdrDayToday .rdrDayNumber span:after {
  bottom: 0 !important;
}

.rdrDateDisplayWrapper {
  display: var(--display-prop);
  background-color: white !important;
}

.rdrDateDisplay {
  visibility: hidden;
}

.chip-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 10px;
  left: 0;
}
